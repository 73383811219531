import type { ContestFormProps } from '@/components/features/contest/ContestForm/type';

import { TO_C_APP } from '@/constants/app';

export const ROUTE = {
  top: () => '/',
  about: () => '/about',

  // 認証
  signup: () => '/signup',
  login: () => '/login',
  forgotPassword: (props: { redirectUrl: string }) =>
    `${TO_C_APP.DOMAIN}/forgot-password?redirectUrl=${encodeURIComponent(
      props.redirectUrl
    )}`,
  resetPassword: () => `${TO_C_APP.DOMAIN}/reset-password`,
  emailVerification: () => `${TO_C_APP.DOMAIN}/email-verification`,

  // 応募者マイページ
  applicantMyPage: (slug: string) => `/mypage/${slug}`,
  applicantGroupCreate: (slug: string) => `/mypage/${slug}/profile/create`,
  applicantProfileEdit: (slug: string) => `/mypage/${slug}/profile/edit`,
  applicantMyPageApply: (slug: string) => `/mypage/${slug}/apply`,
  applicantGroupWork: (slug: string) => `/mypage/${slug}/work`,
  applicantGroupPrint: (slug: string) => `/mypage/${slug}/print`,
  forgotPasswordForApplicant: (slug: string, props: { redirectUrl: string }) =>
    `/mypage/${slug}/forgot-password?redirectUrl=${encodeURIComponent(
      props.redirectUrl
    )}`,
  resetPasswordForApplicant: (slug: string) => `/mypage/${slug}/reset-password`,
  emailVerificationForApplicant: (
    slug: string,
    props?: { token: string; redirectUrl?: string }
  ) =>
    `/mypage/${slug}/email-verification${
      props
        ? `?token=${props.token}${props.redirectUrl ? `&redirectUrl=${encodeURIComponent(props.redirectUrl)}` : ''}`
        : ''
    }`,

  contestList: () => '/contest',
  contestCreate: '/contest/create',
  contestEdit: (
    contestId: number,
    initialFocus?: ContestFormProps['initialFocus']
  ) =>
    `/contest/${contestId}/edit${
      initialFocus ? `?focusTarget=${initialFocus}` : ''
    }`,
  contestDetail: (contestId: number) => `/contest/${contestId}`,
  contestPreview: (contestId: number) =>
    `${TO_C_APP.DOMAIN}/contest/${contestId}`,
  contestPublic: (contestId: number) =>
    `${TO_C_APP.DOMAIN}/contest/${contestId}`,
  contestPlan: (contestId: number) => `/contest/${contestId}/plan`,
  contestResultCeremony: (contestId: number) =>
    `${TO_C_APP.DOMAIN}/work/search?contestId=${contestId}`,
  contestResultCeremonySample: `${TO_C_APP.DOMAIN}/work/search?contestId=171159`,

  contestWork: (contestId: number) => `/contest/${contestId}/work`,

  contestResult: (contestId: number) => `/contest/${contestId}/result`,
  contestResultCreate: (contestId: number) =>
    `/contest/${contestId}/result/create`,
  contestResultEdit: (contestId: number) => `/contest/${contestId}/result/edit`,

  entryFormDetail: (contestId: number) => `/contest/${contestId}/entry_form`,
  entryFormCreate: (contestId: number) =>
    `/contest/${contestId}/entry_form/create`,
  entryFormEdit: (contestId: number) => `/contest/${contestId}/entry_form/edit`,

  // 公募掲載メモ一覧
  contestProhibition: '/contest/prohibition',
  contestProhibitionDetail: (id: number) => `/contest/prohibition/${id}`,
  contestProhibitionEdit: (id: number) => `/contest/prohibition/${id}/edit`,

  /** 主催者向け審査関連画面 */
  screeningCreate: (contestId: number) =>
    `/contest/${contestId}/screening/create`,
  screeningEdit: (contestId: number, stepId: number) =>
    `/contest/${contestId}/screening/${stepId}/edit`,
  screeningEditWork: (contestId: number, stepId: number) =>
    `/contest/${contestId}/screening/${stepId}/edit/work`,
  screeningEditJuror: (contestId: number, stepId: number) =>
    `/contest/${contestId}/screening/${stepId}/edit/juror`,
  screeningEditAssignment: (contestId: number, stepId: number) =>
    `/contest/${contestId}/screening/${stepId}/edit/assignment`,
  screeningDetail: (contestId: number, stepId?: number) =>
    `/contest/${contestId}/screening${
      stepId !== undefined ? `?stepId=${stepId}` : ''
    }`,
  screeningResult: (
    contestId: number,
    stepId: number,
    options?: { jurorId?: number; isPassed?: boolean }
  ) =>
    `/contest/${contestId}/screening/${stepId}/result${
      options
        ? `?${[
            ...(options.jurorId ? [`jurorId=${options.jurorId}`] : []),
            ...(options.isPassed ? [`isPassed=${options.isPassed}`] : []),
          ].join('&')}`
        : ''
    }`,

  /** 審査員向け審査関連画面 */
  assignedScreeningList: () => '/screening',
  assignedScreeningDetail: (stepId: number) => `/screening/${stepId}`,

  /** 審査機能LP */
  screeningLp: () => '/about/screening',
  kouboContestDetail: (contestId: number) =>
    `${TO_C_APP.DOMAIN}/contest/${contestId}`,

  /** 審査員関連 */
  jurorList: () => '/juror',
  jurorDetail: (jurorId: number) => `/juror/${jurorId}`,
  jurorEdit: (jurorId: number) => `/juror/${jurorId}/edit`,

  /** 組織関連 */
  organization: () => '/organization',
  organizationEdit: '/organization/edit',
  organizationProfile: '/organization/profile',
  organizationDetail: (organizationId: number) =>
    `/organization/${organizationId}`,
  organizationMemberList: '/organization/member',
  organizationMemberDetail: (memberId: number) =>
    `/organization/member/${memberId}`,
  organizationMemberEdit: (memberId: number) =>
    `/organization/member/${memberId}/edit`,

  // 分析関連
  analyticsContest: (contestId: number) => `/contest/${contestId}/analytics`,

  // サポート問い合わせ
  contactSupport: () => `${TO_C_APP.DOMAIN}/contact`,

  // 新規問い合わせ
  contactCorporate: () => 'https://koubo.co.jp/contact',

  // Koubo
  koubo: () => TO_C_APP.DOMAIN,

  // あつま〜る ログイン画面
  atumaruLogin: () => 'https://www.koubo.co.jp/system/admin/auth/login/',

  strapi: () => 'https://strapi.koubo.jp/',

  // 規約類
  sitePolicy: () => '/sitepolicy',
  privacy: () => '/privacy',

  // エラー画面
  error404: '/404',
  error500: '/500',
};
